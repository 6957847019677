<template>
  <div class="role">
    <div class="query-header">
      <div class="op">
        <a-button v-if="myAuth.includes('RDP_ROLE_MANAGE')" ghost type="primary" @click="handleClickAddBtn" icon="plus">{{ $t('chuang-jian-jiao-se') }}</a-button>
        <a-button class="refresh" @click="getRoleList" :loading="loading">{{ $t('shua-xin') }}</a-button>
      </div>
    </div>
    <a-table ref="roleListRef" :columns="roleColumns" :data-source="roleList" :pagination="false"
             :rowKey="record=>record.id" size="small" :loading="loading">
      <template slot="action" slot-scope="record">
        <a-button v-if="myAuth.includes('RDP_ROLE_MANAGE')" type="link" @click="handleEditRole('view', record)">
          {{ $t('cha-kan') }}
        </a-button>
        <a-button v-if="myAuth.includes('RDP_ROLE_MANAGE') && !record.innerTag" type="link" @click="handleEditRole('edit', record)">
          {{ $t('bian-ji') }}
        </a-button>
        <a-popconfirm v-if="myAuth.includes('RDP_ROLE_MANAGE') && !record.innerTag" cancel-text="取消" ok-text="确认" :title="$t('que-ding-shan-chu-gai-jiao-se-ma')"
                      @confirm="handleDeleteRole(record)">
          <a-button type="link">{{ $t('shan-chu') }}</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal v-model="showAddNewRoleModal" :title="isEditing ? roleModalType === 'edit' ? $t('bian-ji-jue-se') : '查看角色' : $t('chuang-jian-jue-se')" :width="548" :mask-closable="false">
      <div v-if="showAddNewRoleModal" class="new-role-modal">
        <section>
          <a-form-model ref="addNewRoleForm" :label-col="labelCol"
                        :model="newRole"
                        :rules="rules" :wrapper-col="wrapperCol">
            <a-form-model-item :label="$t('jiao-se-ming-cheng')" prop="roleName">
              <a-input :disabled="isEditing" v-model="newRole.roleName" style="width: 420px;"/>
            </a-form-model-item>
          </a-form-model>
        </section>
        <div class="auth">
          <div class="title">{{ $t('jiao-se-quan-xian') }}</div>
          <div class="content">
            <a-tree v-model="checkedKeys"
                    :tree-data="treeData"
                    checkable
                    :disabled="roleModalType === 'view'"
                    :replace-fields="replaceFields" :expanded-keys.sync="expandedKeys"
            >
            </a-tree>
          </div>
        </div>
        <div class="footer">
          <a-button type="primary" @click="handleAddRole" v-if="roleModalType !== 'view'">{{ isEditing ? $t('xiu-gai') : $t('chuang-jian') }}</a-button>
          <a-button @click="hideAddNewRoleModal">{{ $t('qu-xiao') }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';

export default {
  name: 'Role',
  computed: {
    roleColumns() {
      const { query } = this.roleFilterInfo;
      return [
        {
          title: this.$t('jiao-se-ming-cheng'),
          key: 'roleName',
          filteredValue: [query] || null,
          onFilter: (value, record) => record.roleName.includes(value),
          dataIndex: 'aliasName'
        }, {
          title: this.$t('cao-zuo'),
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ];
    },
    ...mapState(['userInfo', 'myAuth'])
  },
  watch: {
    showAddNewRoleModal(value) {
      if (!value) {
        this.checkedKeys = [];
        this.newRole = { roleName: '' };
        this.checkedAll = false;
      }
    }
  },
  data() {
    return {
      loading: false,
      roleModalType: '',
      replaceFields: {
        title: 'i18nName'
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      isEditing: false,
      mustCheckedKeys: [],
      rules: {
        roleName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('jiao-se-ming-cheng-bu-neng-wei-kong')
          }
        ]
      },
      checkedKeys: [],
      expandedKeys: [],
      newRole: {
        roleName: ''
      },
      treeData: [],
      queryForm: { text: '' },
      showAddNewRoleModal: false,
      roleList: [],
      roleListMap: {},
      selectOptions: [],
      roleFilterInfo: {
        query: ''
      },
      checkedAll: false,
      allAuthKeys: []
    };
  },
  methods: {
    handleEditRole(type, record) {
      this.isEditing = true;
      this.roleModalType = type;
      this.showAddNewRoleModal = true;
      this.newRole = cloneDeep(record);
      this.checkedKeys = [...record.roleLabels];
      this.getAllAuthLabel(true);
    },
    handleAddRole() {
      this.$refs.addNewRoleForm.validate(async (valid) => {
        if (valid) {
          if (!this.checkedKeys.length) {
            this.$Message.error(this.$t('qing-xuan-ze-quan-xian'));
            return;
          }
          const authLabelList = this.checkedKeys.filter((key) => key.includes('_'));
          const data = {
            roleName: this.newRole.roleName,
            authLabelList
          };

          let res;
          if (!this.isEditing) {
            res = await this.$services.createRole({
              data,
              msg: this.$t('tian-jia-jiao-se-cheng-gong')
            });
          } else {
            data.roleId = this.newRole.roleId;
            res = await this.$services.updateRole({
              data,
              msg: this.$t('xiu-gai-jiao-se-cheng-gong')
            });
          }

          if (res.success) {
            await this.getRoleList();
            this.hideAddNewRoleModal();
          }
        }
      });
    },
    hideAddNewRoleModal() {
      this.isEditing = false;
      this.checkedKeys = [];
      this.expandedKeys = [];
      this.mustCheckedKeys = [];
      this.showAddNewRoleModal = false;
    },
    async getAllAuthLabel(edit = false) {
      const subAccountRes = await this.$services.listAllAuthLabel();
      const temp = {};
      const allAuthKeys = [];
      const tempTreeData = [];
      const mustCheckedKeys = [];
      const treeData = [{
        children: [],
        i18nName: this.$t('quan-bu'),
        key: 'ALL',
        mustSelectAndReadOnly: false
      }];
      if (subAccountRes.success) {
        const formatData = (node) => {
          allAuthKeys.push(node.key);
          node.title = node.i18nName;
          if (node.mustSelectAndReadOnly) {
            mustCheckedKeys.push(node.key);
          }

          if (node.children && node.children.length) {
            this.expandedKeys.push(node.key);
            node.children.forEach((child) => {
              formatData(child);
            });
          }
        };
        const { data } = subAccountRes;
        treeData[0].children = data;
        formatData(treeData[0]);

        this.treeData = treeData;
        this.allAuthKeys = allAuthKeys;
        this.mustCheckedKeys = [...mustCheckedKeys];
        this.checkedKeys = [...mustCheckedKeys];
        if (edit) {
          this.checkedKeys = this.newRole.roleLabels;
        }
      }
    },
    handleQuery() {
      this.roleFilterInfo = {
        query: this.queryForm.text
      };
    },
    handleClickAddBtn() {
      this.showAddNewRoleModal = true;
      this.roleModalType = 'edit';
      this.getAllAuthLabel();
    },
    async handleDeleteRole(role) {
      const data = { roleId: role.roleId };
      const res = await this.$services.deleteRole({
        data,
        msg: this.$t('shan-chu-jiao-se-cheng-gong')
      });
      if (res.success) {
        await this.getRoleList();
      }
    },
    async getRoleList() {
      this.loading = true;
      const roleListRes = await this.$services.listRole();
      this.loading = false;
      if (roleListRes.success) {
        this.roleList = roleListRes.data;
        this.generateSelectOptions();
      }
    },
    generateSelectOptions() {
      const temp = [];
      const tempMap = {};
      this.roleList.forEach((role) => {
        const data = {
          name: role.roleName,
          value: role.id
        };
        temp.push(data);
        tempMap[data.value] = data;
      });
      this.selectOptions = temp;
      this.roleListMap = tempMap;
    }
  },
  created() {
    this.getRoleList();
  }
};
</script>

<style lang="less" scoped>
.query-header {
  margin-bottom: 12px;

  .op {
    margin-left: auto;
    & > button + button {
      margin-left: 10px;
    }
  }
}
.new-role-modal {
  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .auth {
    .title {
      margin-bottom: 10px;
    }

    .all {
      height: 30px;
      width: 100%;
      background: #F3F3F3;
      line-height: 30px;
      padding-left: 10px;
      border: 1px solid rgba(204, 204, 204, 1);
      border-bottom: none;
    }

    .content {
      width: 500px;
      height: 270px;
      border: 1px solid rgba(218, 218, 218, 1);
      overflow: scroll;
    }
  }

}
</style>
